.card {



    @media (min-width: 1600px) {
        min-width: 20%;
    }

    @media (max-width : 575px) {
        margin: auto;
    }

    div.cardContent {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        background-color: white;



        div.imageContainer {
            width: 60%;
            display: flex;
            min-height: 150px;
            max-height: 17px;
            border-radius: 20px;

            img {
                border-radius: 20px;
                width: 100%;
            }
        }

        div.textContainer {
            width: 40%;
            padding: 10px 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            span {
                position: absolute;
                bottom: 10px;
                right: 10px;
                color: red;
                font-size: 20px;
                cursor: pointer;
            }

            h3.name {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
                margin-right: auto;
                display: '-webkit-box';
                -webkit-box-orient: 'vertical';
                -webkit-line-clamp: 1;
                overflow: 'hidden';
                text-overflow: 'ellipsis';


            }


        }
    }

}