.bannerDescription {
    padding: 10px 0;
    width: 400px;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width : 600px) {
        width: 90%;

    }

    h1.title {

        @media (max-width : 600px) {
            font-size: 20px;
        }
    }

    .description {
        margin: 30px 0;
    }

    .imageContainer {
        margin: 30px 0;
        display: flex;

        div {
            width: fit-content;
            margin: 0;
            background-color: transparent;
            border-radius: 10px;
            overflow: hidden;

            span {
                width: 150px;
                display: flex;
                flex-direction: column;

                span.uploadIcon {
                    display: flex;
                    align-items: center;
                    font-size: 30px;
                    color: grey;
                    margin: 10px 0;
                }
            }

            h3 {
                color: grey;
                opacity: 0.7;
                font-size: 13px;
            }

        }
    }

    button.submit {
        width: 100%;
        margin: 40px 0 0;
        border-radius: 50px;
        background-image: linear-gradient(90deg, #BA2D52, #D93B30);
        border: none;
        color: white;
        font-size: 1.4rem;
        height: 50px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (max-height:700px) {
            margin: 80px auto 30px;
        }

        @media (max-width : 600px) {
            margin: 40px auto 0;
            width: 80%;
            font-size: 1.2rem;
            height: 40px;

        }

    }

}