.EditLookup {
    width: 560px;
    padding: 20px;
    min-height: -moz-fit-content;
    min-height: fit-content;
    max-height: 500px;
    border-radius: 10px;
    background-color: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .title {
        font-size: 22px;
        margin: 0;
    }

    .inputContainer {
        display: flex;
        flex-direction: row;
        margin: 20px 0;

        .submit {
            margin-left: 10px;
        }

    }

    .tagsContainer {
        overflow: auto;
        height: -webkit-fill-available;
        margin: 5px 0;

        .tag {
            width: 100%;
            position: relative;
            padding: 10px 30px 10px 25px;
            display: inline-block;
            border-radius: 10px;
            background-color: #FFE3E3;
            color: black;
            -webkit-user-select: none;
            user-select: none;
            cursor: grab;
        }

        .removeTag {
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(0, -50%);
        }
    }

    button.save {
        width: -moz-fit-content;
        width: fit-content;
        margin: 15px auto 5px;
        padding: 6px 80px;
        border-radius: 20px;
        background-image: linear-gradient(to right, #B52929, #ED1B1B);
        color: white;
        font-size: 17px;
    }
}

.sortableHelper {
    z-index: 10;
    width: 100%;
    position: relative;
    padding: 10px 30px 10px 25px;
    display: inline-block;
    border-radius: 10px;
    background-color: #f7d4d4;
    color: black;
    -webkit-user-select: none;
    user-select: none;
    cursor: grab;

    .removeTag {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
    }
}