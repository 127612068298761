.authenticateContainer {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;

    .headerContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .dropdown {
            margin: 0;
            margin-left: auto;
            width: 200px;
        }
    }

    .listingContainer {
        width: 100%;
        height: inherit;
    }
}


.modalContentContainer {
    min-width: 30vw;
    min-height: 30dvh;
    max-height: 50vw;
    max-height: 50dvh;
    display: flex;
    flex-direction: column;
    z-index: 3;

    .previewContainer {
        min-width: 30vw;
        min-height: 30dvh;
        max-height: 50vw;
        max-height: 50dvh;
        aspect-ratio: 4/3;

        img {
            width: 100%;
            height: 100%;

        }
    }

    .optionsContainer {
        width: 100%;
        padding: 30px 0;
        display: flex;
        justify-content: center;

        button.accept_reject {
            width: 40%;
            margin: auto;
            cursor: pointer;
            padding: 15px 20px;
            border-radius: 20px;
            color: white;

            @media (max-width:800px) or (max-height:600px) {
                padding: 7px 10px;
            }

            &.accept {
                background-color: #339900
            }

            &.reject {
                background-color: #d87472
            }

        }
    }

}