.lookupsContainer {
    width: 100%;
    height: auto;
    padding: 10px;

    .lookup {
        margin: 0;
        display: flex;
        flex-direction: column;

        &.bottomMargin {
            margin-bottom: 40px;
        }

        .headerContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;

            h1.title {
                margin: 0;
                font-size: 20px;
            }

            span {
                color: black;
                margin: 5px 0 0 10px;
                font-size: 20px;
                cursor: pointer;
            }
        }

        .tagsContainer .tag {
            margin: 5px 10px;
            padding: 10px 25px;
            display: inline-block;
            border-radius: 20px;
            background-color: #FFE3E3;
            color: black;
            -webkit-user-select: none;
            user-select: none;

        }
    }
}