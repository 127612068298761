.table {
    overflow: auto;
    border-radius: 5px;
}


.table::-webkit-scrollbar {
    width: 0;
    height: 5px;
    display: block;
    background-color: rgb(214, 212, 212);

}

.table::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 6px;
    border: 3px solid grey;
}

.table .ant-table {
    background: transparent;
}



.table thead tr th {
    background-color: #B52929;
    border: none;
    overflow: hidden;
    color: white;
    font-size: 1rem;
}

.table .row {
    background-color: #FAFBFD;
    color: black;
    margin: 2px 0;
    border-bottom: none;
    margin: 10px 0;
    border: none;
}

.table .row:hover {
    background-color: #f0eded;
}

.table tbody tr td.ant-table-cell {
    background-color: #FAFBFD;
    border-top: 5px solid white;
    border-bottom: none;
}

.table tbody tr td .ant-empty-description {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.table tbody tr td .ant-empty-description .icon {
    font-size: 40px;
    color: gray;

}

.table tbody tr td .ant-empty-description h1 {
    font-size: 18px;
    margin: 0;
    color: gray;

}


.ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background-color: #FAFBFD;

}

.table .row td {
    border-top: 5px solid #0A0A0A;
    border-bottom: none;
    background: transparent !important;
}

.table thead .row td:hover {
    background: transparent !important;
}


.pagination li {
    background-color: #141414;
    border: none;
}

.pagination li a {
    color: white;
}

.pagination li a:hover {
    color: white;
}

.pagination li.ant-pagination-prev button,
.pagination li.ant-pagination-next button {
    background-color: #141414;
    border: none;
    color: white;
}

.pagination li.ant-pagination-item-active {
    background-image: linear-gradient(180deg, #202339, #222741);
}

.pagination li.ant-pagination-item-active a {
    color: white;
}

.ant-pagination-options {
    display: none !important;
}