.protectedLayout {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;




    .background {
        display: none;

        @media (max-width:991px) {
            display: block;
            position: fixed;
            z-index: 9;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.301);
        }
    }

    .menu {
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: overlay;
        padding: 0 15px;
        background-color: white;
        transition: 0.3s;

        @media (max-width:991px) {
            position: absolute;
            left: -300px;
            z-index: 10;
        }

        &.collapsed {
            width: 350px;

        }

        &.notCollapsed {
            width: 90px;
            align-items: center;
        }

        .drawer {
            display: none;
            text-align: center;
            width: 100%;
            height: 20px;
            margin: 25px auto 50px;


            span {
                cursor: pointer;
                background-color: transparent;
                font-size: 25px;
            }

            &.collapsed {
                text-align: right;
            }

            @media (max-width:991px) {
                display: block;
            }
        }

        .logo {
            width: 100%;
            margin: 25px auto 50px;
            text-align: center;
            padding: 0 12%;

            &.notCollapsed {
                padding: 0;
            }

            img {
                height: 60px;
                cursor: pointer;
                width: 100%;

                &.imageNotCollapsed {
                    max-width: 60px;
                }

            }

            @media (max-width : 991px) {
                display: none;
            }
        }

        .item {
            margin: 2px 0;
            height: 60px;
            padding: 18px 20px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            color: gray;
            font-weight: 600;

            &.active {
                background-image: linear-gradient(to right, #B52929, #ED1B1B);
                border-radius: 15px;
                color: white;
            }

            p {
                margin: 0 10px;
                min-width: max-content;
            }
        }
    }


    .routeContent {
        position: relative;
        width: 100%;
        background-color: #FAFBFD;
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;

        .content {
            width: 100%;
            height: -webkit-fill-available;
            display: flex;
            overflow: hidden;
            padding: 0 20px;

        }

        .Navbar {
            margin: 0 20px;
            padding: 25px 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid lightgray;

            @media (max-width:768px) {
                padding: 12px 10px;
            }

            .titleContainer {
                margin-right: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                span.toggleButton {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin: 0 10px 0 0;
                    font-size: 20px;

                    @media (max-width:768px) {
                        font-size: 16px;
                    }

                }

                h3.title {
                    font-size: 22px;
                    font-weight: 700;
                    margin: 0;


                    @media (max-width:768px) {
                        font-size: 18px;
                    }

                }
            }

            .date {
                margin-right: auto;
                display: flex;
                font-size: 1.4rem;
                color: gray;
                justify-content: center;
                align-items: center;

                p {
                    margin: 0 20px;
                    font-size: 1rem;
                }
            }

            .search {
                display: flex;
                flex-direction: row;
                width: 20rem;
                height: auto;
                min-height: 30px;
                padding: 10px 20px;
                background-color: white;
                border-radius: 10px;
                justify-content: center;
                align-items: center;

                input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    padding: 0 10px;
                    color: gray;
                    font-size: 16px;
                    border: none;
                    outline: none;
                }

                .searchButton {
                    width: 35px;
                    height: 30px;
                    border-radius: 100%;
                    background-image: linear-gradient(to right, #B52929, #ED1B1B);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    cursor: pointer;
                    font-size: 1.3rem;
                }


            }

        }
    }



}